<i18n>
	{
		"en": {
			"loadFund": "Load Fund",
			"transferFund": "Transfer Fund",
			"withdrawFund": "Withdraw Fund",
			"statements": "Statements",
			"Mobile Topup": "Mobile Topup",
			"Internet Payment": "Internet Payment",
			"Utility & TV Payment": "Utility & TV Payment",
			"Learn": "Learn",
			"Flight": "Flight",
			"Insurance": "Insurance",
			"Movies": "Movies",
			"Cable Car": "Cable Car"
		},
		"np": {
			"loadFund": "लोड कोष",
			"transferFund": "स्थानान्तरण कोष",
			"withdrawFund": "कोष निकाल्नुहोस्",
			"statements": "लेनदेन",
			"Mobile Topup": "मोबाइल टपअप",
			"Internet Payment": "इन्टरनेट भुक्तानी",
			"Utility & TV Payment": "उपयोगिता र टिभी भुक्तानी",
			"Learn": "सिक्नुहोस्",
			"Flight": "उडान",
			"Insurance": "बीमा",
			"Movies": "चलचित्र",
			"Cable Car": "केबल कार"
			}
	}
</i18n>
<template lang='pug'>
	.container
		//.show-message Withdraw fund is disabled on purpose.
		.show-message(v-if='kycNotFilled') As your account is not KYC verified, you can only perform transactions below Rs. 5000. 
			router-link.ml-2(to='/know-your-customer') Fill Now
		.show-message(v-if='kycInVerificationProcess') KYC is being verified, you can only perform transactions below Rs. 5000.
		.show-message(v-if='commentsFromKyc') KYC Verification failed because 
			u {{commentsFromKyc.comments}}
			router-link.ml-2(to='/know-your-customer') Update Now
		.box-top-row
			.row
				.col-6.col-lg-3
					router-link.card(to='/load-fund')
						.card-body
							img(:src="`${PUBLIC_URL}assets/img/icon-load-fund.svg`" alt="Load Fund" )
						.card-footer
							h4 {{ $t("loadFund") }}
				.col-6.col-lg-3
					router-link.card(to='/transfer-fund')
						.card-body
							img( :src="`${PUBLIC_URL}assets/img/icon-exchange-arrows.svg`" alt="Transfer Fund" )
						.card-footer
							h4 {{ $t("transferFund") }}
				.col-6.col-lg-3
					router-link.card(to='/withdraw-fund')
						.card-body
							img( :src="`${PUBLIC_URL}assets/img/icon-withdraw.svg`" alt="Withdraw Fund" )
						.card-footer
							h4 {{$t("withdrawFund")}}
				.col-6.col-lg-3
					router-link.card(to='/statements')
						.card-body
							img( :src="`${PUBLIC_URL}assets/img/icon-statements.svg`" alt="Statements" )
						.card-footer
							h4 {{$t("statements")}}
		transition(name='fade' mode='out-in')
			.box-second-row.row(v-if="serviceCategories.length != 0" key='1')
				.col-6.col-md-4(v-for='serviceCategory in serviceCategories' :key="serviceCategory._id")
					.card.cursor-pointer(@click="serviceClicked(serviceCategory._id, serviceCategory.slug)")
						.card-body
							.icon
								img(:src="FILE_SERVER+'Services/Category/Icon/'+serviceCategory.icon.nameOnServer"
									:alt="serviceCategory.name")
						.card-footer
							h4 {{$t(serviceCategory.name)}}
			div(v-else key='2')
				.card-loader
</template>

<script>
import { FILE_SERVER } from "@/helpers/general";
import "@/assets/loading.scss";
import api from "@/helpers/api";
export default {
  name: "home",
  methods: {
	serviceClicked(id, slug) {
	  if (slug === "MOBILE_TOPUP") this.$router.push(`/topup`);
	  else if(slug === "HEALTH_CARE") this.$router.push(`/health-care`);
	  else if(slug === "MOVIES") this.$router.push(`/movies`);
	  else if(slug === "CREDITCARD") this.$router.push(`/credit-card`)
	  else this.$router.push(`/services/${slug.toLowerCase()}`);
	}
  },
  computed: {
	FILE_SERVER() {
	  return FILE_SERVER;
	}
  },
  async mounted() {
	let categories = await api.serviceCategories();
	  this.serviceCategories = categories.filter(sc => {
		return !["WALLET", "CONNECT", "LIFESTYLE", "STICKER"].includes(sc.slug);
	  });
	if (await api.pinNotCreated()) {
	  this.$router.push("/set-pin");
	}
	const kycVerfication = await api.isKycNotVerified();
	this.kycNotFilled = kycVerfication.notFilled;
		this.kycInVerificationProcess = kycVerfication.inProgress;
		this.commentsFromKyc = kycVerfication.rejected;
  },
  data() {
	return {
	  serviceCategories: "",
	  PUBLIC_URL: process.env.BASE_URL,
	  loading: true,
	  kycNotFilled: false,
			kycInVerificationProcess: false,
			commentsFromKyc: ""
	};
  }
};
</script>
